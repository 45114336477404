<template>
  <div class="dropdown__wrappper" ref="dropdownWrapper">
    <div class="custom-dropdown" @click="toggleDropdown">
      {{ selectedCountry.dialCode }}
      <img src="../../assets/images/erflog/DownArrow.png" alt="Dropdown Arrow" class="dropdown-arrow">
    </div>
    <div v-if="showOptins" class="dropdown-options">
      <div v-for="country in countries" :key="country.code" @click="selectCountry(country)">
        {{ country.dialCode }} - {{ country.en }}
      </div>
    </div>
  </div>
</template>
<script>
import {countries} from '@/utils/countries.js';
import firebase from "@/utils/firebaseInit.js";
import { dbCollections } from '@/utils/firebaseCollection';

const db = firebase.firestore()
export default {
  data() {
    return {
      selectedCountry: {"ru": "Индия","lt": "Indija","tr": "Hindistan","en": "India","flag": "🇮🇳","code": "IN","dialCode": "+91","mask": "99999 99999"},
      countries,
      phoneNumber: '',
      validPhoneNumber: true,
      showOptins: false,
      getData: true
    }
  },
  props: {
    selectedCode: {
      type: Object,
      default: ()=> { return {
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999"
      }
    },
    }
  },
  watch:{
    selectedCode: function (val) {
      this.selectedCountry = val;
    },
  },
  created() {
    db.collection(dbCollections.SETTING).doc("phoneNumberCountryCodes").get().then((resss)=>{
      if(resss.exists == true) {
        this.countries = this.countries.filter((ee)=>{
          return resss.data().phoneNumberCountryCodes.includes(ee.code)
        })
      } else {
        this.countries = this.countries.filter((ee)=>{
          return ee.code === "IN"
        })
      }
      this.getData = false;
    })
    .catch((error)=>{
      this.countries = this.countries.filter((ee)=>{
        return ee.code === "IN"
      })
      this.getData = false;
      console.error(error);
    })
  },
  mounted(){
    this.$emit('country-selected', this.selectedCountry);
    document.body.addEventListener('click', this.closeOnOutsideclik);
  },
  beforeDestroy() {
    this.getData = false;
    document.body.removeEventListener('click', this.closeOnOutsideclik);
  },
  methods: {
    toggleDropdown() {
      if(this.getData == true) {
        return;
      }
      this.showOptins = !this.showOptins;
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.showOptins = false;
      this.$emit('country-selected', country);
    },
    closeOnOutsideclik(event) {
      if (!this.$refs.dropdownWrapper.contains(event.target)) {
        this.showOptins = false;
      }
    }
  }
}
</script>

<style>
.dropdown__wrappper {
  position: relative;
}
.custom-dropdown {
  border: 0;
  padding: 0.6rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65px;
}
.custom-dropdown::after {
    content: '';
    width: 1px;
    height: 25px;
    background: #ccc;
    position: absolute;
    right: 0;
}
.dropdown-arrow {
  width: 10px;
  height: auto;
  margin-left: 5px;
}
.dropdown-options {
  position: absolute !important;
  top: 100%;
  left: 0;
  width: max-content;
  background-color: #fff;
  z-index: 999;
  border: 1px solid #ccc;
}
.dropdown-options div {
  padding: 0 0.6rem 0 0.6rem;
  cursor: pointer;
}
.dropdown-options div:hover {
  background-color: #0d4ea0;
  color: white !important;;
}

</style>