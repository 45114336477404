function createPhoneNumberRegex(lengths) {
    let regexPattern;
    if (lengths && lengths.length > 0) {
        const minLength = Math.min(...lengths);
        const maxLength = Math.max(...lengths);
        const maskRegex = new RegExp(`^\\d{${minLength},${maxLength}}$`);
        regexPattern = maskRegex;
    } else {
        regexPattern = /^\d{10}$/;
    }
    return regexPattern;
}
export default createPhoneNumberRegex;