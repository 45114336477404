//DIPSHA KALARIYA --> Global Validation Function For Validation in All Forms
export const ValidationFunction = {
    //Its Contains only Character value Like dipsha (DIPSHA)
    OnlyCharacter: function (event) {
      const char = String.fromCharCode(event.keyCode)
      if (!/[a-zA-Z ]/.test(char)) {
        event.preventDefault()
      }
    },
    //Its Contains only number value Like 458 (DIPSHA)
    OnlyNumber: function (event) {
      const char = String.fromCharCode(event.keyCode)
      if (!/[0-9]/.test(char)) {
        event.preventDefault()
      }
    },
    //Its Contains only number value Like 458 Without 0 value (DIPSHA)
    OnlyIntegerNumber: function (event) {
      // const char = String.fromCharCode(event.keyCode)
      // if (!/[0-9]/.test(char)) {
      //   event.preventDefault()
      // }
      let val = event.target.value.replace(/[^0-9/.]/g,'');      
      if (val.charAt(0) == '0'){
          event.target.value = "";
          event.preventDefault();
      }
      if ((event.which != 46 || val.indexOf('0') != -1) && (event.which < 48 || event.which > 57)) {
          event.preventDefault();
      }
  },
  //MATCH ARRAY VALUE
  isValueExistInArray(arrayObject, value, cb){
    var arr = arrayObject.map((v) => v.replace(" ", "_").toLowerCase());
    var result = arr.includes(
      value.replace(" ", "_").toLowerCase()
    );
    cb(result);
  },
  //Its Contains Float number value Like 45.8 (DIPSHA)
  OnlyFloatNumber(event) {
    let val = event.target.value.replace(/[^0-9/.]/g,'');      
    if (val.charAt(0) == '.' || val.charAt(0) == '0'){
      event.target.value = "";
      event.preventDefault();
    }
    if ((event.which != 46 || val.indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
        event.preventDefault();
    }
  },
  //Its Contains Float number value Like  0.25 (DIPSHA)
  OnlyFloatWithNumber(event){
    let val = event.target.value.replace(/[^0-9/.]/g,'');
    if ((event.which != 46 || val.indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
        event.preventDefault();
    }
  }
}